import BaseBean from "@/utils/BaseBean";

export interface ICxListDataObj {
    utilInst:CxListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class CxListUtil extends BaseBean{
    public dataObj:ICxListDataObj

    constructor(proxy:any,dataObj:ICxListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //查询所有的品牌
        this.dataObj.otherParams.brandData=await this.utils.OrderProviderApi.getAllBrand({});
        //查询所有的国家
        this.dataObj.otherParams.countryData=await this.utils.OrderProviderApi.getAllCountry({});
    }
}