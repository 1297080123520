import CxCard from './CxCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import CxListUtil,{ICxListDataObj} from './cxListUtil';
import language from './cxLanguage'
export default defineComponent ({
    name: 'CxList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ICxListDataObj=reactive<ICxListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: CxCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/cx/pageData')
            },
            otherParams:{
                brandData:[],
                countryData:[]
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CxListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'brandId'==params.comboId){
                    return dataObj.otherParams.brandData;
                }
                if(params && 'countryId'==params.comboId){
                    return dataObj.otherParams.countryData
                }
            }
        })

        const formatPageInfo=(options:any)=>{

            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});