export default {
    zh:{
        cx:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
                brandId:'品牌',
                brandId_placeholder:'请选择品牌',
                countryId:'上市国家',
                countryId_placeholder:'请选择上市国家',
            },
            columns:{
                F_BRAND_ID:'品牌',
                F_NAME:'名称',
            },
            form:{
                brandId:'品牌',
                brandId_placeholder:'请选择品牌',
                brandId_rule: '请选择品牌',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
                countryId:'上市国家',
                countryId_placeholder:'请选择上市国家',
                countryId_rule: '请选择上市国家',
            },
        }
    },
    en:{
        cx:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
                brandId:'品牌',
                brandId_placeholder:'请选择品牌',
                countryId:'上市国家',
                countryId_placeholder:'请选择上市国家',
            },
            columns:{
                F_BRAND_ID:'品牌',
                F_NAME:'名称',
            },
            form:{
                brandId:'品牌',
                brandId_placeholder:'请选择品牌',
                brandId_rule: '请选择品牌',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
                countryId:'上市国家',
                countryId_placeholder:'请选择上市国家',
                countryId_rule: '请选择上市国家',
            },
        }
    }
}